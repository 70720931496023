import React, { useState, useEffect } from "react";
import { Modal, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

//A check to only allow desktop users to continue to the website, if a mobile phone user
// opens the website, they will be shown a notification message

const DesktopOnly = ({ children }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isDesktop) {
    return (
      <Modal
        title={null}
        open={!isDesktop}
        footer={null}
        closable={false}
        centered
        icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
        bodyStyle={{ textAlign: "center" }}
      >
        <ExclamationCircleOutlined
          style={{ fontSize: "24px", color: "#faad14", marginBottom: "16px" }}
        />
        <Title level={4}>Access Unavailable</Title>
        <Paragraph>
          This website is optimized for desktop use. Please access it from a
          desktop or laptop computer.
        </Paragraph>
      </Modal>
    );
  }

  return <>{children}</>;
};

export default DesktopOnly;
